import colors from "./colors"


export default {
  colors,
  breakpoints: ["40em", "52em", "64em"],
  // media:[
  //    (theme) =>`screen and (min-width: ${ theme.breakpoints[0]})`,
  //  (theme) =>`screen and (min-width: ${ theme.breakpoints[1]})`,
  //    (theme) =>`screen and (min-width: ${theme.breakpoints[2]})`,
  // ],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    menu: '"Anek Latin", sans-serif', //'"Anek Latin SemiCondensed", sans-serif',
    body: '"Open Sans", sans-serif',
    heading: '"Anek Latin", sans-serif', //'"Anek Latin SemiCondensed", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 22,24, 32, 48, 64, 96],
  fontWeights: {
    menu: 300,
    body: 300,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  // colors: {
  //   text: "#000",
  //   background: "#fff",
  //   primary: "#07c",
  //   secondary: "#30c",
  //   muted: "#f6f6f6",
  // },
  radii: {
    mini:"4px",
    button: "10px",
    small: "12px",

    medium: "16px",
    large: "24px",
    xl: "36px",
    circle: "99999px",
  },

  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    body: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
  },
  buttons: {
    navigation: {
      fontFamily: "menu",
      // fontSize: [4, 4,3],
      fontWeight: "menu",
      padding: 0,
      textDecoration: "none",
      color: "inherit",
      background: "none",
      border: "none",
      transitionProperty: "color",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
      ":hover": {
        cursor: "pointer",
      },
    },
    light: {
      fontFamily: "menu",
      fontSize: [3],
      fontWeight: "menu",
      px: 3,
      py: 0,
      textDecoration: "none",
      background: `none`,
      borderRadius: `circle`,
      border: theme => `1px ${theme.colors.primary} solid`,
      color: "inherit",
      transitionProperty: "background",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
      ":hover": {
        background: `secondary`,
        cursor: "pointer",
      },
    },
    lightInvert: {
      fontFamily: "menu",
      fontSize: 5,
      fontWeight: "menu",
      ml:`-5px`,
      px: 3,
      py: 0,
      textDecoration: "none",
      background: `none`,
      borderRadius: `circle`,
      border: theme => `1px ${theme.colors.secondary} solid`,
      color: theme => `${theme.colors.secondary} `,
      transitionProperty: "background",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
      ":hover": {
        // background: `secondary`,
        cursor: "pointer",
      },
    },
    small: {
      fontFamily: "menu",
      fontSize: [2],
      fontWeight: "menu",
      px: 2,
      py: 0,
      textDecoration: "none",
      background: `none`,
      borderRadius: `circle`,
      border: theme => `1px ${theme.colors.primary} solid`,
      color: "inherit",
      transitionProperty: "background",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
      ":hover": {
        
        background: `secondary`,
        cursor: "pointer",
      },
    },
    tag: {
      fontFamily: "menu",
      fontSize: [2],
      fontWeight: "menu",
      px: 2,
      py: 0,
      textDecoration: "none",
      background: `secondary`,
      // border: theme => `1px ${theme.colors.secondary} solid`,

      borderRadius: `mini`,
      color: "inherit",
      transitionProperty: "background",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
      ":hover": {
        background: `light`,
        cursor: "pointer",
        // border: theme => `1px ${theme.colors.primary} solid`,

      },
    },
    field: {
      textDecoration: "none",
      borderRadius: `medium`,
      transitionProperty: "all",
      transitionDuration: "0.2s",
      backgroundColor: `light`,

      transitionTimingFunction: "ease-in-out",
      ":hover": {
        transform: `scale(1.02)`,
        // backgroundColor: `secondary`,
        cursor: "pointer",
      },
    },
    blank: {
      appearance: `none`,
      border: `0`,
      background: `transparent`,
      cursor: `pointer`,
      ".sr-only": { display: `none` },
      ":focus": { outline: `none` },
    },
  },
  sizes: {
    container: "1280px",
    narrow: "1024px",
    wide: "1440px",
    tight: "848px",
    avatar: "48px",
    navGroupBoxMin: "300px",
    navGroupBoxMax: "340px",
    navIcon: "32px",
    navIconSmall: "30px",
  },

  shadows: {
    large:
      "0px 4px 8px 0px #2E29330A, 0px 4px 24px 0px #2E293314, 0px 8px 24px 0px #473F4F29",
  },

  layout: {
    container: {
      maxWidth: theme => `${theme.sizes.container}`,
      width: `100%`,
      ml: `auto`,
      mr: `auto`,
      paddingLeft: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
      paddingRight: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
    },
    full: {
      width: `100%`,
      ml: `auto`,
      mr: `auto`,
      paddingLeft: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
      paddingRight: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
    },

    wide: {
      maxWidth: theme => `${theme.sizes.wide}`,
      width: `100%`,
      ml: `auto`,
      mr: `auto`,
      paddingLeft: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
      paddingRight: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
    },
    narrow: {
      maxWidth: theme => `${theme.sizes.narrow}`,
      width:`100%`,
      ml: `auto`,
      mr: `auto`,
      paddingLeft: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
      paddingRight: theme => [
        `${theme.space[4]}px`,
        `${theme.space[4]}px`,
        `${theme.space[5]}px`,
        `${theme.space[6]}px`,
      ],
    },
  },

  styles: {
    root: {
      variant: "text.body",
    },
    p: {
      variant: "text.body",
      fontSize: [2, 2, 3],
      small: {
        fontSize: 2,
      },
    },
    h1: {
      variant: "text.heading",
      fontSize: [6, 6, 6, 7],
    },
    h2: {
      variant: "text.heading",
      fontSize: [5, 5, 6],
    },
    h3: {
      variant: "text.heading",
      fontWeight: "body",
      fontSize: 5,
    },
    h4: {
      variant: "text.heading",
      fontSize: 4,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    ul: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    li: {
      variant: "text.body",
      fontSize: [2, 2, 3],
      mt:1,
      small: {
        fontSize: 2,
      },
    },
  },
  underline: {
    color: "variant",
    position: "relative",
    color: "variant",
    textDecoration: "none",
    display: "inline-block",
    "&::after": {
      content: "''",
      position: "absolute",
      width: "100%",
      transform: "scaleX(0)",
      height: "1px",
      bottom: "0",
      left: "0",
      backgroundColor: "primary",
      transformOrigin: "bottom right",
      transition: "transform 0.25s ease-out",
    },
    "&:hover&:not(.nav__single_active)::after": {
      transform: "scaleX(1)",
      transformOrigin: "bottom left",
    },

  },

  
  // sectionFull: {
  //   width: `100%`,
  //   mx: `auto`,
  //   px: [4, 6],
  // },

  // // Max. Breite mit Padding

  // sectionWide: {
  //   // display: `flex`,
  //   px: [4, 6],
  //   mt: 6,
  //   mx: `auto`,
  //   width: `100%`,

  //   flex: `1 1 auto `,
  //   maxWidth: (theme) => `${theme.sizes.wide}`,
  // },
  // sectionMain: {
  //   // display: `flex`,
  //   px: [4, 6],
  //   mt: 6,
  //   mx: `auto`,
  //   width: `100%`,

  //   flex: `1 1 auto `,
  //   maxWidth: (theme) => `${theme.sizes.container}`,
  // },
}
