export default {
  text: "#002351",
  primary: "#002351",
  secondary: "#E9D2B4",
  light: "#FEF2E6",
  lighter: "#FAF4EB",
  dark: "#041326",

  variant: "#165425",
  white: "#fff",
  black: "#000",
  lightBlue: "#DBEBF2",
  lightPrimary: "#E8EBEF",
  lightVariant: "#E4EFE7",
  alert: "#790033",
}
