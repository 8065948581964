import merge from "lodash/merge"
import baseTheme from "./theme"
import colors from "./colors"

import styles from "./components-styles"
// import "../components/layout.css";

export default merge({}, baseTheme, {
  colors,
  styles,
})
